import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

import HemneoLogoSvg from './hemneo-logo.svg';

const HeaderContainer = styled.header`
  margin-bottom: 8px;
  padding: 0;
  border-bottom: 1px solid #ccc;
`

const HeaderContainerInner = styled.div`
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  align-items: top;
  justify-content: space-between;

  @media (min-width: 800px) {
    justify-content: flex-start;
  }
`

const HeaderLogoLink = styled(Link)`
  display: flex;
  margin: 0;
  padding: 16px 16px 16px 8px;
  color: #000;
  text-decoration: none;
  font-weight: bold;
`

const HeaderLogoImg = styled.img`
  width: 141px;
  height: 27px;
  margin: 0;
`

const HeaderLogoTagline = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 8px;
  color: #777;
  text-transform: uppercase;
`

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <HeaderContainerInner>
      <HeaderLogoLink to="/">
        <HeaderLogoImg src={HemneoLogoSvg} alt="Hemneo" />
        <HeaderLogoTagline>Reko</HeaderLogoTagline>
      </HeaderLogoLink>
    </HeaderContainerInner>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
