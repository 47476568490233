const hemneoColors = {
  B1: '#00baf1', // Hemneo B1 Signalblå
  B2: '#016e8f', // Hemneo B2 Blå
  B3: '#012d3b', // Hemneo B3 Mörkblå
  B4: '#becdd1', // Hemneo B4 Blå bakgrund
  B5: '#ccd8db', // Hemneo B5 Mellanblå bakgrund
  B6: '#dae5e8', // Hemneo B5 Ljusblå bakgrund
  black: '#000', // Svart
  white: '#fff', // Vit
};

export default hemneoColors;
